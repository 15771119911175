import {apiGet} from '@api/api'
import {ApiRes} from '@type/api'
import {ICopier} from '@api/copy/leader/getLeaderKeysForDashboard'

export const apiGetLeaderCopiersAll = async (
    key_id: string,
    limit?: number,
    offset?: number,
): Promise<ApiRes<ICopier[]>> => {
    return await apiGet(`copyleader/copiers/${key_id}?limit=${limit}&skip=${offset}`)
}
