import {apiGet} from '@api/api'

export type BotType = 'copybot' | 'autobot'
export type BotStatus = 'running' | 'paused'

export const apiGetMyBots = async (botType?: BotType) => {
    return await apiGet<MyBots.Res>(`bot/mine?type=${botType}`)
}

export declare module MyBots {
    export interface CopyKey {
        id: string
        user_id: string
        copy_lead_name: string
        thumbnail?: string
        exchanges: string[]
        risk?: number
        copier?: number
        max_copier?: number
        type: BotType
    }

    export interface Autobot {
        id: string
        name: string
        exchanges: string[]
        symbols?: string[]
        logo?: string
    }

    export interface Bot {
        id: string
        profit_rate: number
        earning: number
        exchange: string
        thumbnail: string
        original_key_name: string
        copy_key: CopyKey
        status: BotStatus
        key_id: string
        name: string
        start_at: Date
        balance: number
        profit: number
        type: BotType
        copy_key_id?: string
        autobots?: Autobot[]
        source_ids?: string[]
        reason: string
    }

    export interface Res {
        total_earning: number
        total_profit_rate: number
        bots?: Bot[]
    }
}
