import {apiGet} from '@api/api'
import {ILeague} from '@api/rank/league/league'

export const apiGetLeagueDetail = async (id?: string, language?: string) => {
    return await apiGet<ILeague>(`league/${id}`, {cookies: {language: language}})
}

export interface IEventLeague {
    common: {
        baseColor: string
        textColor: string
        mainColor: string
        isJoinMainColor?: string
        descColor?: string
        bgColor?: string
        subBgColor?: string
        subTextColor?: string
    }
    introduce?: {
        titleColor?: string
        bg: string
        shadow: string
        register: string
        bgImage: string
        joinButtonText?: string
    }
    description?: {
        title?: string
        text?: string
    }
    prizePool?: {
        background?: string
        borderClass?: string
        shadow?: string
        dataBox?: string
        border?: string
        bar?: {
            default?: string
            charge?: string
        }
        dot?: {
            default?: {
                charge?: string
                circle?: string
            }
            active?: {
                charge?: string
                circle?: string
            }
        }
        text?: {
            default?: string
            active?: string
            data?: string
            value?: string
        }
    }
    navigation?: {
        default?: string
        active?: string
        bar?: string
        bg?: string
    }
    rank?: {
        default?: string
        active?: {
            border?: string
            bg?: string
            text?: string
            badge?: string
        }
        profit?: string
        button?: string
        border?: string
        buttonText?: string
        no_rank_fill?: string
    }
    bonuses?: {
        bg?: string
        title?: string
        text?: string
        arrowColor?: string
        desc?: string
        close?: {
            circle?: string
            border?: string
        }
        button?: string
        buttonText?: string
    }
    requirements?: {
        icon?: string
        text?: string
        desc?: string
        button?: string
        bg?: string
    }
    howto: {
        bg?: string
        step?: string
        desc?: string
        button?: string
        line?: string
    }
    ruleAndDisclaimers: {
        bg?: string
        accordion?: string
    }
    link?: {
        register?: string
    }
    id: string
    currency?: string
    animation?: {
        text?: string
        textColor?: string
        opacity?: string
    }
}
