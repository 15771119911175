import {ApiRes} from '@type/api'
import {apiGet} from '@api/api'
import {IApiGetSidebarKeyIdProps} from '@api/user/sidebar/getSidebar'

export interface ICopyHistory {
    id: string
    key_id: string
    name: string
    type: string
    copy_key_id: string
    start_at: string
    deleted_at?: string
    user_id: string
    balance: number
    earning: number
    profit?: number
    leader_name: string
    leader_thumbnail: string
    status: string
    copier_name?: string
    initial_balance?: number
    deposit?: number
    withdraw?: number
    exchange?: string
}

export const apiGetCopyHistory = async (
    user_id: string,
    props?: IApiGetSidebarKeyIdProps,
): Promise<ApiRes<ICopyHistory[]>> => {
    return await apiGet(`copier/previous?user_id=${user_id}`, {cookies: props?.cookie})
}
