import {ApiRes} from '@type/api'
import {apiGet} from '@api/api'
import {IApiGetSidebarKeyIdProps} from '@api/user/sidebar/getSidebar'
import {BotStatus} from '@api/key/copyfolio/getKeyCopyfolio'

export interface ICopierMineBot {
    balance?: number
    bot_id?: string
    copier_name?: string
    copy_key?: {
        copier: number
        copy_lead_name: string
        exchanges: string[]
        id: string
        max_copier: number
        risk: number
        risk_level: number
        thumbnail: string
        type: string
        user_id: string
    }
    copy_key_id?: string
    deposit?: number
    earning?: number
    exchange?: string
    id?: string
    initial_balance?: number
    key_id?: string
    last_24_h_earning?: number
    last_24_h_profit?: number
    leader_name?: string
    name?: string
    expected_pay?: number
    next_payment_amount?: number
    next_payment_date?: string
    original_key_name?: string
    pay_finalized?: boolean
    profit?: number
    profit_rate?: number
    settings?: {
        mirror?: number | boolean
        stop_loss?: number | boolean
        take_profit?: number | boolean
    }
    start_at?: string
    status?: BotStatus
    thumbnail?: string
    reason?: string
    type?: string
    user_id?: string
    withdraw?: number
    owed_earning?: number
}

export interface ICopierMineBotList {
    bots: ICopierMineBot[]
    total_earning: number
    total_profit_rate: number
}

export const getCopierMineBot = async (props?: IApiGetSidebarKeyIdProps): Promise<ApiRes<ICopierMineBotList>> => {
    return await apiGet(`copier/mine`, {cookies: props?.cookie})
}
