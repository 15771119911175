import {apiGet} from '@api/api'
import {ApiRes} from '@type/api'

export interface ISearchUser {
    id: string
    name: string
    thumbnail: string
    is_leader?: boolean
    exchange?: string
}

export const apiGetSearchUser = async (searchText: string): Promise<ApiRes<Array<ISearchUser>>> => {
    // return await apiGet(`user/search?n=${searchText}`)
    return await apiGet(`search/${searchText}`)
}
