import {apiGet} from '@api/api'
import {ApiRes} from '@type/api'

export interface ILeaderRegisterKey {
    id: string
    name: string
    exchange: string
    lead_state: number[]
    access_key: string
    current_balance: number
    all_time_profit: number
    blocked_until: string
}

export const apiGetLeaderJoinableKey = async (): Promise<ApiRes<ILeaderRegisterKey[]>> => {
    return await apiGet(`copyleader/join`)
}
