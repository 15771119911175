import {apiGet} from '@api/api'
import {ApiRes} from '@type/api'
import {IAsset, IOpenOrder} from '@type/profile'
import {IPosition} from '@api/key/copyfolio/getKeyCopyfolio'
import {IApiGetSidebarKeyIdProps} from '@api/user/sidebar/getSidebar'
import {ExchangeType} from '@type/exchange'

export interface ILatestBalance {
    id: string
    user_id: string
    key_id: string
    assets: IAsset[]
    current_position: IPosition[]
    total_amount_in_base: number
    exchange: string
    day_timestamp: number
    created_at: number
}

export interface IAssetSummary {
    initial_balance: number
    deposit: number
    withdraw: number
    earning: number
    profit: number
    start_at: string
    end_at: string
}

export interface IAssetSummaryByDuration {
    1: IAssetSummary
    1000: IAssetSummary
    30: IAssetSummary
}

export interface IKeyPortfolio {
    id: string
    key_name: string
    exchange: ExchangeType | string
    created_at: string
    copier?: any
    latest_balance: ILatestBalance
    win: number
    loss: number
    mdd: number
    average_hold_seconds_per_month: number
    tf: number
    asset_summary: IAssetSummaryByDuration
    open_orders?: IOpenOrder[]
    is_copier?: boolean
    is_leader?: boolean
    user_id?: string
}

export const apiGetKeyPortfolio = async (
    id: string,
    props?: IApiGetSidebarKeyIdProps,
): Promise<ApiRes<IKeyPortfolio>> => {
    return await apiGet(`key/portfolio/${id}`, {cookies: props?.cookie})
}
