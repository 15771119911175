import {apiGet, apiPost} from '@api/api'
import {ApiRes} from '@type/api'
import {IUserRank} from '@api/rank/rank'
import {IConfirmResponse} from '@api/auths/register/Register'

export const apiGetLeagueList = async (language?: string) => {
    return await apiGet<ILeague[]>('league', {cookies: {language: language}})
}

export const apiGetLeagueListNotStarted = async () => {
    const {data, ...result} = await apiGet<ILeague[]>('league')
    return {...result, data: data.filter(league => new Date().getTime() / 1000 < league.start_at)}
}

export const apiGetActiveLeagueList = async () => {
    return apiGet<ILeague[]>('/league?active=1')
}

export const apiGetProgressLeagueList = async () => {
    const {data, ...result} = await apiGet<ILeague[]>('/league')

    const sortLeagues = (a, b) => {
        // is_active 가 true 인 리그가 우선적으로 정렬된다. (공식 리그를 앞에, BJ 리그 뒤에 정렬하기 위함)
        const valueB = b.is_active === true ? 1 : 0
        const valueA = a.is_active === true ? 1 : 0
        return valueB - valueA
    }

    return {
        ...result,
        data: data.filter(v => v.end_at > new Date().getTime() / 1000).sort(sortLeagues),
    }
}

export const apiGetEndedLeagueList = async (lastCreatedAt?: string) => {
    let offsetQuery = ''
    if (lastCreatedAt) {
        offsetQuery = `lc=${lastCreatedAt}`
    }

    const {data, ...result} = await apiGet<ILeague[]>(`/league?${offsetQuery}`)

    return {
        ...result,
        data: data.filter(v => v.end_at < new Date().getTime() / 1000),
    }
}

export const apiGetPreviousLeagueList = async (endBefore?: number) => {
    if (!endBefore || endBefore === 0) {
        endBefore = Math.trunc(new Date().getTime() / 1000)
    }
    return apiGet<ILeague[]>(`/league?endBefore=${endBefore}`)
}

export const apiGetLeagueRankList = async (league_id: string, offset?: number, limit?: number, language?: string) => {
    return await apiGet<IUserRank[]>(`rank?l=${league_id}&o=${offset}&limit=${limit}`, {cookies: {language: language}})
}

export const LeagueJoinApiStatus = {
    RegisterByPassed: 5040,
    MinimumBalance: 5041,
    LockedDueToKeyDelete: 5042,
    LeagueExchange: 5039,
    Unknown: 5028,
}

export const apiLeagueJoin = async (league_id: string): Promise<ApiRes<IConfirmResponse>> => {
    return await apiPost(`ljoin`, {league_id: league_id})
}

export interface ILeague {
    id: string
    type?: string
    name?: string
    names?: INames
    thumbnail?: string
    thumbnails?: IThumbnail
    start_at: number
    end_at: number
    register_by: number
    is_active?: boolean
    icon?: string
    created_by: string
    created_at: number
    start_url: string
    start_urls?: IStartUrls
    end_urls?: any
    rank?: number
    members_count?: number
    meta?: {
        theme_color?: string
        banner_pc?: string
        banner_mobile?: string
    }
    prize_pool?: string
    prize_pools: IPrizePool
    subtitle?: string
    subtitles?: RootObjectSubtitles
    can_join?: boolean
    filter: IFilter
    is_joined?: boolean
    my_league_rank?: number
    my_league_score?: number
    show?: number
    autojoin?: boolean
}

export interface INames {
    en: string
    ko: string
}

export interface IThumbnail {
    en: string
    ko: string
}

export interface IStartUrls {
    en: string
    ko: string
}

export interface IPrizePool {
    en: string
    ko: string
}

export interface RootObjectSubtitles {
    en: string
    ko: string
}

export interface IFilter {
    min_balance?: string
    exchange?: string
}

export interface IEventPrizePool {
    prize: string
    min?: number
    max?: number
    prizes: string[]
}
