import {apiGet} from '@api/api'
import {ApiRes} from '@type/api'
import {IAsset} from '@type/profile'
import {IKeyInvoice} from '@api/user/account/invoice/getInvoviceKey'

export interface ICopyKey {
    id: string
    user_id: string
    copy_lead_name: string
    thumbnail: string
    exchanges: string[]
    risk: number
    copier: number
    max_copier: number
    type: string
}

export interface IGraph {
    day: number
    profit: number
    earning: number
    balance: number
}

export interface IKeyCopyfolioSummary {
    starting_balance: number
    end_balance: number
    withdraw: number
    deposit: number
    profit_rate: number
    earning: number
}

export interface IPosition {
    symbol: string
    amount: number
    avg_buy_price: number
    current_price: number
    leverage: number
    cross_margin: boolean
    is_margin: boolean
    unrealised_pnl: number
    exchange: string
    is_edit: boolean
    key_id: string
    dual_mode_side: string
    extra: string
    added_at: string
    logo: string
    side: string
    profit: number
    name: string
    initial_value: number
    current_value: number
    pnl: number
    avg_entry_price: number
    is_base: boolean
}

export interface ILeaderAssetPosition {
    assets: IAsset[]
    current_position: IPosition[]
}

export type BotStatus = 'running' | 'paused' | 'close'

export interface IKeyCopyfolio {
    key_id: string
    name?: string
    leader_name?: string
    type?: string
    copy_key_id: string
    start_at?: string
    pause_at?: string
    balance?: number
    profit?: number
    status?: BotStatus
    id: string
    bot_id: string
    user_id: string
    original_key_name: string
    exchange: string
    copy_key?: ICopyKey
    graph?: IGraph[]
    earning?: IGraph[]
    assets?: IAsset[]
    summary?: IKeyCopyfolioSummary
    next_payment_date?: string
    next_payment_amount?: number
    last_24_h_profit?: number
    last_24_h_earning?: number
    positions?: IPosition[]
    leader?: ILeaderAssetPosition
    settings?: ICopyOptionSettings
    pending_invoice?: IKeyInvoice
    owed_earning?: number
    next_owed_earning?: number
    cant_resume_reason?: string[]
}

export interface ICopyOptionSettings {
    mirror?: boolean
    stop_loss?: number
    take_profit?: number
}

export const apiGetKeyCopyfolio = async (key_id: string): Promise<ApiRes<IKeyCopyfolio>> => {
    return await apiGet(`copier/info/${key_id}`)
}
