import {apiGet} from '@api/api'
import {ApiRes} from '@type/api'

export interface IHistory {
    id: string
    user_id: string
    key_id: string
    type: string
    order_type: string
    exchange: string
    market: string
    price: number
    amount: number
    normalized_symbol: string
    price_in_base: number
    created_at: number
}

export const HistorySortTypes = {
    ALL: '',
    BUY: 'buy',
    SELL: 'sell',
    DEPOSIT: 'deposit',
    WITHDRAW: 'withdraw',
} as const
export type HistorySortType = typeof HistorySortTypes[keyof typeof HistorySortTypes]

export const apiGetKeyHistory = async (
    key_id: string,
    lastDate: number = 0,
    sort: HistorySortType = HistorySortTypes?.ALL,
    isLeader: boolean = false,
): Promise<ApiRes<IHistory[]>> => {
    return await apiGet(`key/history/${key_id}?ld=${lastDate}&t=${sort}&leader=${isLeader}`)
}
