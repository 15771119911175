import {apiGet} from '@api/api'
import {ILeague} from '@api/rank/league/league'

export interface IShowLeagues {
    end_at: number
    id: string
    name: string
    show: number
    start_at: number
}

export interface IShowLeague {
    count: number
    leagues: ILeague[]
}

export const apiGetLeagueShow = async (language?: string) => {
    return apiGet<IShowLeague>('leagues/show', {cookies: {language: language}})
}
