import {apiGet} from '@api/api'
import {ApiRes} from '@type/api'

export interface IUserRank {
    id: string
    status?: string
    name: string
    thumbnail?: string
    created_at?: number
    privacy_level?: number
    flags?: string[]
    is_following?: boolean
    active_exchanges: string[]
    copy_exchanges?: any[]
    rank: number
    score?: number
    risk?: number
    rank_change?: number
    pnl?: number
    is_paid_leader?: boolean
    is_pro?: boolean

    simple_graph?: number[]
    badges?: string[]
}

export const apiGetRank = async (
    exchange: string,
    period: string,
    sort?: string,
    offset?: string,
    limit?: number,
    language?: string,
): Promise<ApiRes<IUserRank[]>> => {
    return await apiGet(`rank?r=${period}&e=${exchange}&so=${sort ?? ''}&o=${offset}&limit=${limit || 60}`, {
        cookies: {language: language},
    })
}

export const apiGetSubscriberRank = async (
    exchange: string,
    period: string,
    sort: string,
    offset?: string,
    language?: string,
): Promise<ApiRes<IUserRank[]>> => {
    return await apiGet(`frank?r=${period}&e=${exchange}&so=${sort}&o=${offset}`, {cookies: {language: language}})
}

export const apiGetLeagueSubscriberRank = async (
    league_id: string,
    language?: string,
): Promise<ApiRes<IUserRank[]>> => {
    return await apiGet(`frank?l=${league_id}`, {cookies: {language: language}})
}
