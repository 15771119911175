import {apiPost} from '@api/api'
import {ApiRes} from '@type/api'
import {IConfirmResponse} from '@api/auths/register/Register'

export const apiPostPausedCopier = async (
    id: string,
    close_position: boolean = false,
): Promise<ApiRes<IConfirmResponse>> => {
    return await apiPost(`bot/status`, {id: id, status: 'pause', close_position})
}
