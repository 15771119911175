import {apiGet, apiPost} from '@api/api'
import {ApiRes} from '@type/api'

export interface CreateLeaderParams {
    id: string
    copy_lead_name: string
    thumbnail?: string
    leader_status?: string
    min_copy_balance?: number
    max_copier?: number
}

export interface CreateLeaderRes {
    exchanges: string[]
    minimum_balance: number
    minimum_days_to_lead: number
    copier_minimum_balance: number
    commission_percent: number
    commission_payment_period: number
    days_locked_before_lead_again: number
}

export const apiPostCreateLeader = async (param: CreateLeaderParams): Promise<ApiRes<CreateLeaderRes>> => {
    return await apiPost(`copyleader/join`, param)
}
