import {apiGet, IApiGetProps} from '@api/api'
import {ApiRes} from '@type/api'

export interface IApiGetKeyPnlProps extends IApiGetProps {
    key_id: string
    limit?: number
    before?: number
}

export const apiGetKeyDailyPnl = async (props: IApiGetKeyPnlProps): Promise<ApiRes<IKeyPeriodPnl[]>> => {
    return await apiGet(`key/earn/daily/${props.key_id}?before=${props?.before}&limit=${props?.limit}`, {
        cookies: props?.cookies,
    })
}

export interface IKeyPeriodPnl {
    day: number
    earning: number
}
