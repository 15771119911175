import {ApiRes} from '@type/api'
import {apiGet, IApiGetProps} from '@api/api'
import {IKeyPeriodPnl} from '@api/key/portfolio/getKeyDailyPnl'

interface IApiGetCopierDailyPnl extends IApiGetProps {
    bot_id: string
    limit?: number
    before?: number
}

export const apiGetCopierDailyPnl = async (props: IApiGetCopierDailyPnl): Promise<ApiRes<IKeyPeriodPnl[]>> => {
    return await apiGet(`copier/earn/daily?bot_id=${props.bot_id}&before=${props?.before}&limit=${props?.limit}`, {
        cookies: props.cookies,
    })
}
