import {apiPost} from '@api/api'
import {ApiRes} from '@type/api'
import {IConfirmResponse} from '@api/auths/register/Register'

export const apiEditEntryPrice = async (
    id: string,
    symbol: string,
    buy_average: string,
): Promise<ApiRes<IConfirmResponse>> => {
    return await apiPost(`key/edit`, {id: id, symbol: symbol, buy_average: buy_average})
}
