import {apiGet} from '@api/api'
import {ApiRes} from '@type/api'
import {IApiGetSidebarKeyIdProps} from '@api/user/sidebar/getSidebar'

interface ICopyLeaderMeKey {
    all_time_profit: number
    copier: number
    copier_pnl?: number
    copy_lead_name: string
    current_balance: number
    exchange: string
    id: string
    is_paid_leader: boolean
    max_copier: number
    mdd: number
    min_balance: number
    profit: {
        all_time: number
        monthly: number
    }
    risk: number
    started_at: string
    status: string
    thumbnail: string
}

export interface ICopyLeaderMe {
    copier_pnl: number
    keys: ICopyLeaderMeKey[]
    pending_pay: number
    total_aum: number
    total_paid: number
    blocked_until?: string
    accumulated_earning?: number
}

export const getLeaderMyKeys = async (props?: IApiGetSidebarKeyIdProps): Promise<ApiRes<ICopyLeaderMe>> => {
    return await apiGet(`copyleader/me`, {cookies: props?.cookie})
}
