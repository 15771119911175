import {apiGet} from '@api/api'
import {ApiRes} from '@type/api'

export interface ILeaderDetailData {
    deposit: number
    earning: number
    end_balance: number
    mwr: number
    profit: number
    start_balance: number
    twr: number
    when: number
    withdraw: number
    total_earning?: number
    total_profit?: number
}

export interface ILeaderDetail {
    detail_data?: ILeaderDetailData[]
    mwr_denominator?: number
    twr_denominator?: number
}

export const apiGetLeaderDetail = async (
    id: string,
    type: string,
    when?: number,
    before?: string,
): Promise<ApiRes<ILeaderDetail>> => {
    return await apiGet(`copyleader/detail/${id}?type=${type}&when=${when}${before ? `&before=${before}` : ''}`)
}
