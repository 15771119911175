import {ApiRes} from '@type/api'
import {apiGet} from '@api/api'

export interface ICopyTradingTerms {
    terms?: string
    version?: string
}

export const apiGetCopyTradingTerms = async (): Promise<ApiRes<ICopyTradingTerms>> => {
    return await apiGet(`copier/terms`)
}
