import {apiPost} from '@api/api'
import {ApiRes} from '@type/api'

export interface ICreateCopyParams {
    key_id: string
    name?: string
    type: 'copybot'
    copy_key_id: string
    terms_version: string
    settings?: ICreateCopyParamSettings
}

export interface ICreateCopyParamSettings {
    take_profit?: number
    stop_loss?: number
    mirror?: boolean
}

export interface ICreateCopyRes {
    id: string
    created_at: number
    exchange: string
}

export const apiPostCreateCopier = async (params: ICreateCopyParams): Promise<ApiRes<ICreateCopyRes>> => {
    return await apiPost(`bot/create`, params)
}
