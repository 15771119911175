import {ApiRes} from '@type/api'
import {apiGet} from '@api/api'
import {IAssetSummary, ILatestBalance} from '@api/key/portfolio/getKeyPortfolio'
import {IOpenOrder} from '@type/profile'

export interface ISimpleUser {
    id: string
    name: string
    thumbnail: string
}

export interface IAssetSummarys {
    1: IAssetSummary
    1000: IAssetSummary
    30: IAssetSummary
}

export interface ILeaderInfo {
    id: string
    name: string
    thumbnail?: string
    status: string
    exchanges: string[]
    start_at: string
    copiers: number
    max_copiers: number
    win: number
    loss: number
    mdd: number
    hold_avg: number
    tf: number
    tpd: number
    risk: number
    min_balance: number
    commission_rate: number
    commission_interval: number
    leader: ISimpleUser
    asset_summary: IAssetSummarys
    latest_balance: ILatestBalance
    open_orders?: IOpenOrder[]
    total_earning?: number
    aum?: number
    expected_pay?: number
    risk_level?: number
}

export const apiGetLeaderInfo = async (key_id: string): Promise<ApiRes<ILeaderInfo>> => {
    return await apiGet(`copyleader/portfolio/${key_id}`)
}

interface ILeaderMini {
    id: string
    exchange: string
    win: number
    loss: number
    balance: number
    copiers: number
    max_copiers: number
    mdd: number
    hold_avg: number
    tf: number
    min_balance: number
}

export const apiGetLeaderMiniInfo = async (key_id: string): Promise<ApiRes<ILeaderMini>> => {
    return await apiGet(`copyleader/mini/${key_id}`)
}
