import {ApiRes} from '@type/api'
import {apiGet} from '@api/api'
import {IHistory} from '@api/key/portfolio/getHistory'

export const apiGetLeaderHistory = async (
    key_id: string,
    skip: number,
    limit?: number,
): Promise<ApiRes<IHistory[]>> => {
    return await apiGet(`copyleader/history/${key_id}?o=${skip}&limit=${limit}`)
}
