import {apiGet} from '@api/api'
import {ApiRes} from '@type/api'

export interface IPaymentHistory {
    id: string
    copier_name?: string
    amount?: number
    paid_at?: string
    copier_id?: string
    copier_thumbnail?: string
}

export interface IApiBaseProps {
    cookie?: any
}

export interface IApiGetPaymentHistoryIdProps extends IApiBaseProps {}

export const apiGetLeaderPaymentHistory = async (
    userId: string,
    limit?: number,
    before?: number,
    props?: IApiGetPaymentHistoryIdProps,
): Promise<ApiRes<IPaymentHistory[]>> => {
    return await apiGet(`leader/payment/history/${userId}?limit=${limit}${before ? `&before=${before}` : ''}`, {
        cookies: props?.cookie,
    })
}
