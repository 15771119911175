import {apiGet} from '@api/api'
import {ApiRes} from '@type/api'

export interface IPost {
    user: any
    is_liked?: boolean
    is_pinned?: boolean
    id: string
    user_id: string
    title?: string
    content?: string
    images?: any
    likes_count: number
    comments_count: number
    created_at: number
    visibility: number
    language: string
    type: string
    views: number
    channel: string
}

export interface IPostList {
    data: IPost[]
}

export const apiGetPostList = async (channel: string, limit?: number, skip?: number): Promise<ApiRes<IPostList>> => {
    return await apiGet(`feed/all?c=${channel}&n=${skip}&limit=${limit}`)
}
