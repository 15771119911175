import {apiGet} from '@api/api'
import {ApiRes} from '@type/api'
import {IApiGetSidebarKeyIdProps} from '@api/user/sidebar/getSidebar'

export interface ICopyLeader {
    key_id: string
    leader_name: string
    thumbnail: string
    aum: number
    copiers: ICopier[]
    earn: number
    copier_count: number
    max_copier: number
    start_at: string
    expected_pay: number
}

export interface ICopier {
    key_id: string
    username: string
    thumbnail: string
    start_at: string
    balance: number
    pnl: number
    profit_rate: number
    paid_leader: number
    next_payment: string
    expected_pay: number
    pending_pay: boolean
    owed_earning?: number
}

export const getLeaderKeysForDashboard = async (
    userId: string,
    props?: IApiGetSidebarKeyIdProps,
): Promise<ApiRes<ICopyLeader[]>> => {
    return await apiGet(`leader/keys/${userId}`, {cookies: props?.cookie})
}
