import {apiGet} from '@api/api'
import {ApiRes} from '@type/api'

export interface ICopyLeaderStartCopy {
    id: string
    copy_lead_name: string
    current_balance: number
    thumbnail: string
    exchange: string
    all_time_profit: number
    risk: number
    mdd: number
    profit: PeriodProfit
    copier: number
    status: string
    min_balance: number
    max_copier: number
    copier_pnl: number
    started_at: string
    is_paid_leader?: boolean
}

export interface PeriodProfit {
    all_time: number
    monthly: number
}

export const getLeaderKeysForStartCopy = async (userId: string): Promise<ApiRes<ICopyLeaderStartCopy[]>> => {
    return await apiGet(`copyleader/keys/${userId}`)
}
