import {apiGet} from '@api/api'
import {ApiRes} from '@type/api'
import {IpStatus, ProIpStatus} from '@type/profile'

export const apiGetMyCopiableKeys = async (
    exchange?: string,
    leader_key?: string,
): Promise<ApiRes<IMyCopiableKey[]>> => {
    return await apiGet(`copier/keys/${exchange ?? 'all'}?leader=${leader_key}`)
}

export interface IMyCopiableKey {
    id: string
    name: string
    exchange: string
    current_balance: number
    total_balance: number
    is_copier: boolean
    is_leader: boolean
    is_autobot: boolean
    is_unified_account: boolean
    trade_permission: boolean
    pro_ip_status?: ProIpStatus
    ip_status?: IpStatus
    owed_earning?: number
}
