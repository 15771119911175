import {apiPost} from '@api/api'
import {ApiRes} from '@type/api'
import {IConfirmResponse} from '@api/auths/register/Register'
import {safeReturn} from '@util/etc'
export const apiRegisterKey = async (
    exchange: string,
    accessKey: string,
    secretKey?: string,
    passPhrase?: string,
    name?: string,
): Promise<ApiRes<IConfirmResponse>> => {
    return await apiPost('key', {
        exchange: exchange,
        access_key: accessKey,
        pass_phrase: safeReturn(passPhrase),
        secret_key: safeReturn(secretKey),
        name: safeReturn(name),
    })
}
