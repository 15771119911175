import {KeyPermission, KeyState} from '@type/profile'
import {apiPost} from '@api/api'
import {ApiRes} from '@type/api'

export interface IKeyRefreshResponse {
    id: string
    permission: KeyPermission
    state: KeyState
}

export const apiRefreshKey = async (id?: string): Promise<ApiRes<IKeyRefreshResponse>> => {
    return await apiPost(`key/refresh`, {key_id: [id]})
}
