import {IAsset} from '@type/profile'
import {IPosition} from '@api/key/copyfolio/getKeyCopyfolio'
import {ApiRes} from '@type/api'
import {apiGet} from '@api/api'
import {IApiGetSidebarKeyIdProps} from '@api/user/sidebar/getSidebar'

interface ICopyLeaderSimple {
    key_id: string
    user_id: string
    name: string
    thumbnail: string
}

export interface ICopierLatestBalance {
    total_amount_in_base: number
    current_positions: IPosition[]
    created_at: number
    assets: IAsset[]
}

export type CopierStatus = 'running' | 'paused'
export type CopierReason =
    | 'stop-loss'
    | 'take-profit'
    | 'user-self'
    | 'unpaid-invoice'
    | 'leader-left'
    | 'user-balance'
    | 'user-key'
    | 'no-pro-ip'
    | 'period-ended'

export interface ICopierAsset {
    leader: ICopyLeaderSimple
    key_name: string
    user_id: string
    key_id: string
    exchange: string
    profit: number
    earning: number
    status: CopierStatus
    copy_stopped_at: string
    copy_started_at: string
    latest_balance: ICopierLatestBalance
    last_day_earning: number

    reason: CopierReason
}

export interface ICopierDashboardRes {
    total_copy_earning: number
    last_day_earning: number
    copier_key_asset: ICopierAsset[]
}

export const apiGetCopierDashboard = async (
    user_id: string,
    props?: IApiGetSidebarKeyIdProps,
): Promise<ApiRes<ICopierDashboardRes>> => {
    return await apiGet(`copier/current/${user_id}`, {cookies: props?.cookie})
}
